/**
 * Script: index.tsx
 * The index file binds everything together and renders the application.
 *
 * Dependencies are
 * react-app-polyfill/ie11        - Polyfills for react / ie 11 compatibility
 * react-app-polyfill/stable      - Polyfills for react / ie 11 compatibility
 * element-closest-polyfill       - Required by slate to work properly in IE 11
 * focus-visible                  - Polyfill for focus-visible (applying a class when element is focused via keyboard, not mouse)
 * React        - react           - React
 * ReactDom     - react-dom       - React DOM methods
 * Sentry       - @sentry/react   - Sentry automatically logs errors in the client or when the backend throws errors
 * Integrations - @sentry/tracing - Sentry automatically logs errors in the client or when the backend throws errors
 * App          - ./app           - Main component the Skrible application
 */

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "element-closest-polyfill";
import "focus-visible";

import React from "react";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactDOM from "react-dom";

import App from "./app";

// Only run axe on local development. NEver include this in production as it makes the application extremely slow
if (process.env.REACT_APP_ENV === "development") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require("react-axe");
  axe(React, ReactDOM, 1000);
}

// Only run sentry on skrible.no and beta.skrible.no
if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://79aea227a8c0c943d833122d97098c23@o4508483999105024.ingest.de.sentry.io/4508484019159120",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
